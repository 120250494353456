<template>
  <div>
    <table-list :tableOption="{size: 'mini'}"
                :loading="loading"
                :columns="columns"
                :data="blockList"
                fit
                stripe
                :pageData="pageData"
                :headData="headData"
                @handlePageChange="handlePageChange"
                @handleSizeChange="handleSizeChange">
      <!-- 使用列表插槽 -->
      <template #height="{row}">
        <span v-if="row.height">{{toThousands(row.height)}}</span>
        <span v-else>无</span>
      </template>
      <template #size="{row}">
        <span>{{toThousands(row.size)}}</span>
      </template>
      <template #promiseCode="{row}">
        <span v-if="row.promiseCode===101">PoL</span>
        <span v-else-if="row.promiseCode===201">PoS</span>
        <span v-else-if="row.promiseCode===301">Paxos</span>
      </template>
      <template #hashAlgorithm="{row}">
        <span v-if="row.hashAlgorithm===201">sha3</span>
        <span v-else-if="row.hashAlgorithm===202">sm3</span>
      </template>
      <template #signAlgorithm="{row}">
        <span v-if="row.signAlgorithm===101">rsa</span>
        <span v-else-if="row.signAlgorithm===102">sm2</span>
      </template>
      <template #timestamp="{row}">
        {{ tf(row.timestamp, 'YYYY-MM-DD HH:mm:ss') }}
      </template>

      <template #timeline="{row}">
        {{ tf(row.timestamp+row.timeline, 'YYYY-MM-DD HH:mm:ss') }}
      </template>

      <template #coinBase="{row}">
        ...{{ row.coinBase.substr(row.coinBase.length-26,row.coinBase.length-1) }}
      </template>

      <template #parentHash="{row}">
        ...{{ row.parentHash.substr(row.parentHash.length-26,row.parentHash.length-1) }}
      </template>
      <!-- 区块哈希 -->
      <template #hash="{row}">
        <div class="div-row">
          <popover :width="500"
                   :shard="row.shardName"
                   :value="row.hash"
                   :isSubStr="false"
                   :divClass="'shenglue-1'"></popover>
          <i class="el-icon-document-copy copy-icon"
             @click="copy($event, row.hash)"></i>
        </div>
      </template>

      <template #sign="{row}">
        ...{{ row.sign.substr(row.sign.length-26,row.sign.length-1) }}
      </template>
      <!-- 查看按钮 -->
      <!-- <template #row="{row}">
        <el-button class="table-btn" type="text"
                   icon="el-icon-search table-btn"
                   @click="handleDetail(row.shardName, row.height)"> 查看</el-button>
      </template> -->

    </table-list>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import TableList from '@/components/TableList'
import Popover from '@/components/Popover'
import timeFormat from '@/utils/timeFormat.js'
import publicFunction from '@/utils/publicFunction.js'
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        // console.log('监听:', this.datas)
        // 清空数据
        this.blockList = []
        this.pageData.currentPage = 1
        this.getData(1)
      },
      deep: false,
      immediate: true
    }
  },
  components: {
    TableList,
    Popover
  },
  data() {
    return {
      loading: false,
      blockList: [],
      pageData: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        // layout: 'prev, pager, next, jumper, ->, total',
        currentPage: 1 // 当前页
      }, // 分页配置
      columns: [
        {
          label: '序号',
          type: 'index',
          align: 'center',
          show: true,
          width: 70
        },
        {
          label: '分片名',
          prop: 'shardName',
          align: 'center',
          show: true,
          minWidth: 110
          // width: 120
        },
        {
          label: '区块高度',
          prop: 'height',
          show: true,
          enableSlot: true,
          align: 'center',
          minWidth: 110
          // width: 120
        },
        {
          label: '打包账本数',
          prop: 'size',
          show: true,
          enableSlot: true,
          align: 'center',
          minWidth: 110
          // width: 110
        },
        {
          label: '共识算法',
          prop: 'promiseCode',
          show: true,
          enableSlot: true,
          align: 'center',
          minWidth: 110
          // width: 110
        },
        {
          label: '哈希算法',
          prop: 'hashAlgorithm',
          show: true,
          enableSlot: true,
          align: 'center',
          minWidth: 110
          // width: 110
        },
        {
          label: '区块哈希',
          prop: 'hash',
          show: true,
          enableSlot: true,
          maxWidth: 550,
          minWidth: 250
          // minWidth: 200
        },
        {
          label: '出块时间',
          prop: 'timestamp',
          show: true,
          enableSlot: true,
          width: 190
        },
        {
          prop: 'operators',
          label: '操作',
          fixed: 'right',
          align: 'right',
          width: 100,
          children: [
            {
              icon: 'el-icon-view',
              label: '查看',
              clickEvent: (thisVue, row) => {
                thisVue.handleDetail(row.shardName, row.height)
              },
              showFilter: () => {
                return true
              }
            }
          ]
        }
      ],
      headData: {
        title: '区块列表'
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 数字加逗号转化为金额类型
    toThousands(num) {
      return publicFunction.toThousands(num)
    },
    // 跳转详情页
    handleDetail(shardName, height) {
      if (this.$route.path === '/DataPlatform/block') {
        this.$router.push({
          name: 'blockDetails',
          query: {
            shardName: shardName,
            searchKey: height
          }
        })
      } else {
        this.$router.push({
          name: 'chainBlockDetails',
          query: {
            shardName: shardName,
            searchKey: height
          }
        })
      }
    },
    // 时间转换
    tf(time, format) {
      return timeFormat(time, format)
    },
    // 判断是否包含中文
    hasChinese(value) {
      // var reg = new RegExp(/.*[/u4e00-/u9fa5]+.*$/)
      if (escape(value).indexOf('%u') < 0) {
        return false // 不包含中文
      } else {
        return true // 包含中文
      }
    },
    async getData(currentPage) {
      if (currentPage === 1) {
        this.loading = true
        let option = {
          page: 0,
          limit: this.pageData.size,
          shard: this.datas.shard,
          height: this.datas.height || null,
          hash: this.datas.hash || null,
          startTime: Date.parse(this.datas.timeList[0]) || null,
          endTime: Date.parse(this.datas.timeList[1]) || null,
          startHeight: this.datas.startHeight || null,
          endHeight: this.datas.endHeight || null
        }
        let { data } = await this.$api.chainQL.blockList(option)
        if (data.listBlock !== null && data.listBlock !== '') {
          this.pageData.total = data.listBlock.total
          this.blockList = data.listBlock.block
        }
        // console.log('链数据1:', data)
      } else if (currentPage > 1) {
        this.loading = true
        let option = {
          page: this.pageData.size * (currentPage - 1),
          limit: this.pageData.size,
          shard: this.datas.shard,
          height: this.datas.height || null,
          hash: this.datas.hash || null,
          startTime: Date.parse(this.datas.timeList[0]) || null,
          endTime: Date.parse(this.datas.timeList[1]) || null,
          startHeight: this.datas.startHeight || null,
          endHeight: this.datas.endHeight || null
        }
        let { data } = await this.$api.chainQL.blockList(option)
        // console.log('链数据2:', data)
        if (data.listBlock !== null && data.listBlock !== '') {
          this.pageData.total = data.listBlock.total
          this.blockList = data.listBlock.block
        }
      }
      this.loading = false
    },
    handlePageChange(goPage) {
      this.pageData.currentPage = goPage
      this.getData(goPage)
    },
    // 配置每页条数
    handleSizeChange(number) {
      this.pageData.currentPage = 1
      this.pageData.size = number
      this.pageData.pageSize = number
      this.getData(1)
    },
    // 一键复制
    copy(e, text) {
      // console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-icon {
  margin-left: 10px;
}
.dict-value {
  font-weight: bold;
  color: #3f536e;
  margin-bottom: 10px;
}
.div-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
// 不换行省略
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
