<template>
  <div class="search-form">
    <el-form ref="form"
             :model="form"
             label-position="right"
             label-width="110px">
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="分片名">
            <el-select class="inputWid"
                       @change="handleSelect"
                       v-model="form.shard"
                       @keypress.enter.native="confirm">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="区块高度">
            <el-input class="inputWid"
                      @keypress.enter.native="confirm"
                      v-model="form.height"
                      type="number"
                      clearable
                      placeholder="请输入区块高度"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="区块哈希">
            <el-input class="inputWid"
                      @keypress.enter.native="confirm"
                      v-model="form.hash"
                      clearable
                      placeholder="请输入区块哈希"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="width:100%">
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="出块时间范围">
            <el-date-picker class="inputWid"
                            v-model="form.timeList"
                            type="datetimerange"
                            :picker-options="pickerOptions"
                            range-separator="-"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="起始高度">
            <el-input class="inputWid"
                      @keypress.enter.native="confirm"
                      v-model="form.startHeight"
                      type="number"
                      clearable
                      placeholder="请输入起始高度"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24"
                :sm="24"
                :md="8"
                :lg="8"
                :xl="8">
          <el-form-item class="inputWid"
                        label="截止高度">
            <el-input class="inputWid"
                      @keypress.enter.native="confirm"
                      v-model="form.endHeight"
                      type="number"
                      clearable
                      placeholder="请输入截止高度"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="width:100%">
        <el-col class="search-btn"
                :xs="24"
                :sm="24"
                :md="24"
                :lg="24"
                :xl="24">
          <el-form-item>
            <el-button type="primary"
                       @click="confirm">查询</el-button>
            <el-button class="form-item-Left"
                       @click="rest">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.getAliases()
  },
  methods: {
    // 分片名切换刷新
    handleSelect() {
      this.$emit('select', this.form.shard)
    },
    // 分片名数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = [
          {
            value: null,
            label: '全部'
          }
        ]
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.options = array
      }
    },
    rest() {
      // 这里重置默认选中第一个选项
      // this.form.shard = this.options[0].value
      this.form.shard = null
      this.form.height = ''
      this.form.hash = ''
      this.form.startHeight = ''
      this.form.endHeight = ''
      this.form.timeList = []
      this.$emit('select', null)
      this.confirm()
    },
    confirm() {
      let _form = {
        shard: this.form.shard,
        height: this.form.height ? this.trim(this.form.height) : '',
        hash: this.form.hash ? this.trim(this.form.hash) : '',
        timeList: this.form.timeList || [], // 当时间范围清空时该值会变为null需要重新赋值为[]
        startHeight: this.form.startHeight ? this.trim(this.form.startHeight) : '',
        endHeight: this.form.endHeight ? this.trim(this.form.endHeight) : ''
      }
      this.$emit('search', _form)
    },
    trim(s) {
      // 去除目标字符串左右两边的空白字符
      return s.replace(/(^\s*)|(\s*$)/g, '')
    }
  }
}
</script>
