var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-list',{attrs:{"tableOption":{size: 'mini'},"loading":_vm.loading,"columns":_vm.columns,"data":_vm.blockList,"fit":"","stripe":"","pageData":_vm.pageData,"headData":_vm.headData},on:{"handlePageChange":_vm.handlePageChange,"handleSizeChange":_vm.handleSizeChange},scopedSlots:_vm._u([{key:"height",fn:function(ref){
var row = ref.row;
return [(row.height)?_c('span',[_vm._v(_vm._s(_vm.toThousands(row.height)))]):_c('span',[_vm._v("无")])]}},{key:"size",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.toThousands(row.size)))])]}},{key:"promiseCode",fn:function(ref){
var row = ref.row;
return [(row.promiseCode===101)?_c('span',[_vm._v("PoL")]):(row.promiseCode===201)?_c('span',[_vm._v("PoS")]):(row.promiseCode===301)?_c('span',[_vm._v("Paxos")]):_vm._e()]}},{key:"hashAlgorithm",fn:function(ref){
var row = ref.row;
return [(row.hashAlgorithm===201)?_c('span',[_vm._v("sha3")]):(row.hashAlgorithm===202)?_c('span',[_vm._v("sm3")]):_vm._e()]}},{key:"signAlgorithm",fn:function(ref){
var row = ref.row;
return [(row.signAlgorithm===101)?_c('span',[_vm._v("rsa")]):(row.signAlgorithm===102)?_c('span',[_vm._v("sm2")]):_vm._e()]}},{key:"timestamp",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.tf(row.timestamp, 'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"timeline",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.tf(row.timestamp+row.timeline, 'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"coinBase",fn:function(ref){
var row = ref.row;
return [_vm._v(" ..."+_vm._s(row.coinBase.substr(row.coinBase.length-26,row.coinBase.length-1))+" ")]}},{key:"parentHash",fn:function(ref){
var row = ref.row;
return [_vm._v(" ..."+_vm._s(row.parentHash.substr(row.parentHash.length-26,row.parentHash.length-1))+" ")]}},{key:"hash",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"div-row"},[_c('popover',{attrs:{"width":500,"shard":row.shardName,"value":row.hash,"isSubStr":false,"divClass":'shenglue-1'}}),_c('i',{staticClass:"el-icon-document-copy copy-icon",on:{"click":function($event){return _vm.copy($event, row.hash)}}})],1)]}},{key:"sign",fn:function(ref){
var row = ref.row;
return [_vm._v(" ..."+_vm._s(row.sign.substr(row.sign.length-26,row.sign.length-1))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }